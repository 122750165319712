import { app } from '~/app';
// @ts-ignore comment-request
import { AtToastPlugin } from '@packages/aliftech-ui';
import Snowflakes from 'magic-snowflakes';

const snowflakes = new Snowflakes({
  zIndex: 0,
  minSize: 10,
  maxSize: 12,
});

snowflakes.start();
app.use(AtToastPlugin);

app.config.globalProperties.$toast = AtToastPlugin;

app.mount('#app');
export const $toast = AtToastPlugin;
